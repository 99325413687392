import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/antd.css'
import 'antd/dist/antd.variable.min.css'
import '../styles/globals.css'
import '../styles/header.css'
import '../styles/sidebar.css'
import { ConfigProvider } from 'antd'
import ProtectRoute from '../utils/ProtectRoute'
import NextNProgress from 'nextjs-progressbar'

import { ToastContainer } from 'react-toastify'

import { AuthProvider } from '../utils/AuthContext'
ConfigProvider.config({
  theme: {
    primaryColor: '#4f46e4',
    primaryColorHover: '#4f46e4',
  },
})
function MyApp({ Component, pageProps }) {
  return (
    <ConfigProvider prefixCls="ant">
      <AuthProvider>
        <Component {...pageProps} />
        <ToastContainer />
      </AuthProvider>
    </ConfigProvider>
  )
}

export default MyApp
