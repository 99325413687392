import React, { useEffect } from 'react'
import SvgComponent from './loader'
import { useRouter } from 'next/router'
import { useAuth } from '../../utils/AuthContext'
const PeopleFirst = (props) => {
  const router = useRouter()
  const { currentUser, loading } = useAuth()
  const height = props.height || '100vh'
  useEffect(() => {
    if (!currentUser && !loading) {
      router.replace('/')
    }
  }, [currentUser, loading])

  return (
    <div className="pp-loader" style={{ height: height }}>
      <SvgComponent />
    </div>
  )
}
export default PeopleFirst
