// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://4e93eeb9655e436f9c6e02557c5231da@o1173805.ingest.sentry.io/6269312',
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'TypeError',
    'ResizeObserver loop limit exceeded',
    "undefined is not an object (evaluating 'this.badge.hide')",
    "Cannot read property 'hide' of undefined",
    "Cannot read properties of undefined (reading 'attr')",
    "Cannot read properties of undefined (reading 'hide')",
    "Cannot read properties of undefined (reading 'html')",
  ],
})
