import * as React from "react";

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Group_408"
    x={0}
    y={0}
    viewBox="0 0 143.4 188.5"
    style={{ maxHeight: "40px" }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        "@keyframes ZuszzzYH_draw1{0%{opacity:1}50%,to{stroke-dashoffset:0;opacity:1}}@keyframes ZuszzzYH_draw2{0%,30%{stroke-dashoffset:269;opacity:1}70%,to{stroke-dashoffset:0;opacity:1}}"
      }
    </style>
    <defs>
      <path
        id="Path_1318_3_"
        d="M26.4 49.7v138.8H64V37.9"
        className="ZuszzzYH_0"
      />
    </defs>
    <clipPath id="Path_1318_1_">
      <use
        xlinkHref="#Path_1318_3_"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <path
      d="M45.2 195.7s1.2-160.8 0-164.8"
      style={{
        strokeDasharray: "165 167",
        strokeDashoffset: 166,
        animation: "ZuszzzYH_draw1 1400ms ease-in-out infinite alternate",
        clipPath: "url(#Path_1318_1_)",
        fill: "none",
        stroke: "#4f46e4",
        strokeWidth: 44,
        strokeMiterlimit: 10,
      }}
    />
    <defs>
      <path
        id="Path_1318"
        d="M77.8 0 64 .5H37.3C34.1 8 28.7 14.3 21.8 18.6 15.2 23 7.8 26 0 27.3v34.1c5-.1 10-1.2 14.5-3.2 4.6-1.8 8.6-4.7 11.8-8.5v138.8H64V37.9l13.8-1.5c16.2 0 29.2 13.2 29.1 29.4-.1 16.1-13.1 29.1-29.1 29.2v36.3c36.3-.1 65.5-29.7 65.4-65.9C143 29.3 113.8.1 77.8 0"
        className="ZuszzzYH_2"
      />
    </defs>
    <clipPath id="Path_1318_2_">
      <use
        xlinkHref="#Path_1318"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <path
      d="M-14.6 46.5s23.3 5.1 57.8-25.1L49 16s73.5-7.8 75.7 42.6-46.9 57.8-46.9 57.8L64 122.2"
      style={{
        strokeDasharray: "268 270",
        strokeDashoffset: 269,
        animation: "ZuszzzYH_draw2 1400ms ease-in-out infinite alternate",
        clipPath: "url(#Path_1318_2_)",
        fill: "none",
        stroke: "#4f46e4",
        strokeWidth: 54,
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);

export default SvgComponent;
